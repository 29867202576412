import dog2 from "../img/dogs/2.jpg";
import dog3 from "../img/dogs/3.jpg";
import dog4 from "../img/dogs/4.jpg";
import dog5 from "../img/dogs/5.jpg";
import dog6 from "../img/dogs/6.jpg";
import dog7 from "../img/dogs/7.jpg";
import dog8 from "../img/dogs/8.jpg";
import dog9 from "../img/dogs/9.jpg";
import dog10 from "../img/dogs/10.jpg";
import dog11 from "../img/dogs/11.jpg";
import dog12 from "../img/dogs/12.JPG";
import dog13 from "../img/dogs/13.jpg";
import dog14 from "../img/dogs/14.jpg";
import dog15 from "../img/dogs/15.JPG";
import dog16 from "../img/dogs/16.JPG";

const dogs = [
  { num: 2, img: dog2 },
  { num: 3, img: dog3 },
  { num: 4, img: dog4 },
  { num: 5, img: dog5 },
  { num: 6, img: dog6 },
  { num: 7, img: dog7 },
  { num: 8, img: dog8 },
  { num: 9, img: dog9 },
  { num: 10, img: dog10 },
  { num: 11, img: dog11 },
  { num: 12, img: dog12 },
  { num: 13, img: dog13 },
  { num: 14, img: dog14 },
  { num: 15, img: dog15 },
  { num: 16, img: dog16 },
];

export { dogs };
