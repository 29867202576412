import React from "react";
import doggo from "./img/golden_pup.jpg";
import "./App.css";
import { dogs } from "./data/dogs";
import { Button, Container, GridList, GridListTile } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function App() {
  const [view, setView] = React.useState("home");
  const isMobile = useMediaQuery("(max-width:599px)");

  return (
    <div className="app">
      <div className="content">
        <h1 onClick={() => setView("home")}>Goggledogs!</h1>
        <h5>Dogs What We Like Goggling At.</h5>
        <Container>
          {view === "home" ? (
            <>
              <div>
                <img src={doggo} alt="dog" className="dogPic" />
              </div>
              <Button
                className="spacedBtn"
                variant="outlined"
                onClick={() => setView("more")}
              >
                See More Dogs
              </Button>
            </>
          ) : (
              <>
                <GridList
                  cellHeight={500}
                  className="gridList"
                  cols={isMobile ? 1 : 2}
                  spacing={5}
                >
                  {dogs.map((dog) => (
                    <GridListTile key={dog.num} cols={1}>
                      <img src={dog.img} alt={dog.img} />
                    </GridListTile>
                  ))}
                </GridList>
                <Button
                  className="spacedBtn"
                  variant="outlined"
                  onClick={() => setView("home")}
                >
                  See Fewer Dogs
              </Button>
              </>
            )}
        </Container>
        <h5>By Colin & Laura™</h5>
      </div>
    </div>
  );
}

export default App;
